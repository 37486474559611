import NextImage from 'next/image'

import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text-v1'

import DoubleQuoteIcon from 'assets/icons/double-quote.svg'

interface IInspirationCardProps {
  data: {
    image: string
    content: string
    name: string
    school?: string
    videoId: string
  }
  onClick: (id: string) => void
}
export const InspirationCard = ({ data, onClick }: IInspirationCardProps) => {
  return (
    <div className="flex-1 min-h-max mb-2">
      <div
        className="group relative rounded-lg mb-2 md:mb-4 cursor-pointer"
        onClick={() => onClick(data.videoId)}
      >
        <NextImage
          src={data.image}
          alt={data.image}
          className="object-cover rounded-lg"
          width={600}
          height={350}
          layout="responsive"
        />
        <div className="absolute inset-0 bg-black/45 flex justify-center items-center rounded-lg">
          <div className="p-3 md:p-4 bg-blue-70 rounded-full flex justify-center items-center scale-100 group-hover:scale-110 ease-in duration-100 bg-fixed">
            <Icon fontSize="default" color="inverse">
              play_arrow
            </Icon>
          </div>
        </div>
      </div>

      <DoubleQuoteIcon className="w-[27px] h-[20px] mb-2 md:mb-6" />

      <div className="text-base md:text-[22px] leading-[22px] md:leading-7 mb-2 md:mb-3">
        {data.content}
      </div>

      {Boolean(data.name) && <Text variant="action">{data.name}</Text>}

      {Boolean(data.school) && (
        <Text color="subdued" variant="action">
          {data.school}
        </Text>
      )}
    </div>
  )
}
