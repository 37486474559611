import React from 'react'
import { LazyMotion, m } from 'framer-motion'

import { INSPIRATION_VIDEO_DATA } from 'app/LandingV2Page/constants'
import { InspirationCard } from 'app/LandingV2Page/components/InspirationCard'
import PopupVideo from 'components/PopupVideo'

const domAnimation = () =>
  import('app/LandingV2Page/utils/domAnimation').then((res) => res.default)

export const Inspiration = () => {
  const [videoId, setVideoId] = React.useState(null)
  const [isPopupVideoOpened, setPopupVideoOpened] = React.useState(false)

  React.useEffect(() => {
    setPopupVideoOpened(Boolean(videoId))
  }, [videoId])

  React.useEffect(() => {
    if (!isPopupVideoOpened) {
      setVideoId(null)
    }
  }, [isPopupVideoOpened])

  return (
    <section
      role="contentinfo"
      aria-label="Inspirasi Guru"
      className="bg-[#F2F8FE] pt-16 md:pt-32 pb-10 md:pb-16 -mt-5"
    >
      <LazyMotion features={domAnimation}>
        <m.div
          className="w-full mx-auto max-w-7xl px-4 md:px-6 mb-7 md:mb-8 lg:mb-11"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ ease: 'easeIn', duration: 0.3, delay: 0.1 }}
          viewport={{ once: true }}
        >
          <p className="text-3xl md:text-4xl lg:text-[54px] leading-8 md:leading-10 lg:leading-[62px] text-[#215ED9] font-semibold max-w-4xl">
            Menjadi salah satu pilihan untuk sumber inspirasi guru
          </p>
        </m.div>
      </LazyMotion>
      <LazyMotion features={domAnimation}>
        <m.div
          className="w-full mx-auto max-w-7xl px-4 md:px-6 grid md:grid-cols-2 gap-x-0 gap-y-6 md:gap-x-6"
          initial={{ opacity: 0, y: '10%' }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ ease: 'easeIn', duration: 0.3, delay: 0.2 }}
          viewport={{ once: true }}
        >
          {INSPIRATION_VIDEO_DATA.map((inspiration, index) => {
            return (
              <InspirationCard
                key={`landing-inspiration-${index}`}
                data={inspiration}
                onClick={setVideoId}
              />
            )
          })}
        </m.div>
      </LazyMotion>

      <PopupVideo
        isOpen={isPopupVideoOpened}
        setIsOpen={setPopupVideoOpened}
        videoId={videoId}
      />
    </section>
  )
}
